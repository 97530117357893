<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback"><img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>{{ $t('bound-bankcard-title') }}</p>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="kbox"></div>
    <div class="addressDiv">
        <div class="addiv1">
            <div class="addiv1_l">{{$t('bound-id')}}：</div>
            <div class="addiv1_r">{{ userInfo.id }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('bound-account')}}：</div>
            <div class="addiv1_r">{{ userInfo.account }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('bound-name')}}：</div>
            <div class="addiv1_r"><input id="xm" type="text" v-model="userInfo.realName" /></div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('bound-bankcard-bank')}}：</div>
            <div class="addiv1_r"><input id="zfb" type="text" v-model="userInfo.bank" /></div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('bound-bankcard-cardno')}}：</div>
            <div class="addiv1_r"><input id="kh" type="text" v-model="userInfo.bankCardNo" /></div>
        </div>
        <div class="addiv1" style="text-align:center">
            <a class="aui-btn aui-btn-danger" @click="submitChange" v-show="showEdit">{{$t('userinfo-modify-data')}}</a>
        </div>
    </div>
</template>
    
<script setup>
import { ref, onMounted } from 'vue'
import { modifyUserInfo } from '@/api/authed/settings'
import { showToast } from 'vant';
import { useI18n } from "vue-i18n";
import { getUserInfo } from '@/api/authed/mine';

const { t } = useI18n();
const userInfo = ref({})
const rex = /^[\u4e00-\u9fa5]{1,}$/
const showEdit = ref(true)
onMounted(() => {
    getUserInfo().then(res => {
        userInfo.value = res.data
        if(userInfo.value.bankCardNo!=null&& userInfo.value.bankCardNo!=''){
            userInfo.value.bankCardNo= hideCardNo(userInfo.value.bankCardNo)
            showEdit.value=false
        }
    })

})

const hideCardNo = (cardNumber)=> {
    if (typeof cardNumber === 'string') {
    // 去除卡号中的空格或其他分隔符
    const cardNumberWithoutSpaces = cardNumber.replace(/\s/g, '');

    // 获取卡号的总长度
    const cardLength = cardNumberWithoutSpaces.length;

    if (cardLength >= 8) {
      const firstFour = cardNumberWithoutSpaces.slice(0, 4);
      const lastFour = cardNumberWithoutSpaces.slice(cardLength - 4);

      return `${firstFour} **** **** ${lastFour}`;
    }
  }

  // 如果输入不是字符串，返回原始输入
  return cardNumber;
}

const submitChange = () => {
    if (userInfo.value.realName === '' || userInfo.value.realName === null || userInfo.value.realName === undefined) {
        showToast(t('bound-bankcard-msg-name-empty'))
        return
    }
   
    if (!rex.test(userInfo.value.realName )) {
        showToast(t('bound-bankcard-msg-name-chinese'))
        return
    }
    if (userInfo.value.bank == '' || userInfo.value.bank == null || userInfo.value.bank == undefined) {
        showToast(t('bound-bankcard-msg-bankname-empty'))
        return
    }

    if (userInfo.value.bankCardNo == '' || userInfo.value.bankCardNo == null || userInfo.value.bankCardNo == undefined) {
        showToast(t('bound-bankcard-msg-cardno-empty'))
        return
    }

    const param = { realName: userInfo.value.realName, bank: userInfo.value.bank, bankCardNo: userInfo.value.bankCardNo, id: userInfo.value.id }
    modifyUserInfo(param).then(res => {
        if (res.code == 200) {
            showToast(t('userinfo-msg-success'))
            localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
        } else {
            showToast(res.msg)
        }
    })
}
</script>