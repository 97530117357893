<template>
    <div class="m0myheader">
        <div class="conbox">
            <div class="conboxL">
                <img src="/images/tx.png" class="tt" />
                <div class="btR">
                    <p class="p1">{{ userOverview ? userOverview.account : '' }}</p>
                    <div class="v1">
                        <img :src="levelImgSrc" />
                        <p>{{ userOverview ? userOverview.nickname : '' }}</p>
                    </div>
                </div>
            </div>
            <div class="conboxR">
                <a v-bind:href="'/settings'">{{ $t('my-setting-text') }}</a>
            </div>
            <div class="clear"></div>
        </div>
        <div class="conbox2">
            <ul>
                <li>
                    <p class="p1">{{ userOverview ? (Number(userOverview.balance) / 100).toFixed(2) : '' }}</p>
                    <p class="p2">{{ $t('my-balance') }}</p>
                </li>
                <li>
                    <p class="p1">{{ userOverview ? Number(userOverview.totalTaxReturned).toFixed(2) : '' }}</p>
                    <p class="p2">{{ $t('my-refund-total') }}</p>

                </li>
                <li>
                    <p class="p1">{{ userOverview ? userOverview.creditScore : '' }}</p>
                    <p class="p2">{{ $t('my-credit') }}</p>
                </li>
            </ul>
        </div>
    </div>
    <div class="clear"></div>
    <div class="mypart1">
        <ul>
            <li>
                <a v-bind:href="'/tabList/0'">
                    <p class="p1">{{ userOverview ? Number(userOverview.waitGroupPurchase).toFixed(2) : '' }}</p>
                    <p class="p2">{{ $t('my-group-blance-wait-group') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/tabList/1'">
                    <p class="p1">{{ userOverview ? Number(userOverview.succeedGroupPurchase).toFixed(2) : '' }}</p>
                    <p class="p2">{{ $t('my-group-blance-success') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/tabList/2'">
                    <p class="p1">{{ userOverview ? Number(userOverview.waitTaxReturn).toFixed(2) : '' }}</p>
                    <p class="p2">{{ $t('my-group-blance-wait-tax') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/tabList/3'">
                    <p class="p1">{{ userOverview ? Number(userOverview.alreadyTaxReturned).toFixed(2) : '' }}</p>
                    <p class="p2">{{ $t('my-group-blance-tax-refund') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/fundDetail'">
                    <img src="../../static/images/my01.png" />
                    <p class="p2">{{ $t('my-group-balance-record') }}</p>
                </a>
            </li>
        </ul>
    </div>
    <div class="kbox"></div>
    <div class="kbox"></div>
    <div class="clear"></div>
    <div class="mypart2">
        <div class="con">
            <div class="pa2_tit">
                <p>{{ $t('my-group-order') }}</p>
                <a v-bind:href="'/tabList/all'">{{ $t('my-group-more') }} ></a>
            </div>
            <ul>
                <li>
                    <a v-bind:href="'/tabList/all'">
                        <div class="ddimg">
                            <img src="../../static/images/my02.png" />
                        </div>
                        <p>{{ $t('my-group-all') }}</p>
                    </a>
                </li>
                <li>
                    <a v-bind:href="'/tabList/0'">
                        <div class="ddimg">
                            <img src="../../static/images/my03.png" />
                        </div>
                        <p>{{ $t('my-group-wait') }}</p>
                    </a>
                </li>
                <li>
                    <a v-bind:href="'/tabList/1'">
                        <div class="ddimg">
                            <img src="../../static/images/my04.png" />
                        </div>
                        <p>{{ $t('my-group-success') }}</p>
                    </a>
                </li>
                <li>
                    <a v-bind:href="'/tabList/2'">
                        <div class="ddimg">
                            <img src="../../static/images/my06.png" />
                        </div>
                        <p>{{ $t('my-group-waittax') }}</p>
                    </a>
                </li>
                <li>
                    <a v-bind:href="'/tabList/3'">
                        <div class="ddimg">
                            <img src="../../static/images/my14.png" />
                        </div>
                        <p>{{ $t('my-group-refunded') }}</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="clear"></div>
    <div class="kbox"></div>
    <div class="kbox"></div>
    <div class="clear"></div>
    <div class="mypart3">
        <ul>
            <li>
                <a v-bind:href="'/mine/recharge'">
                    <img src="../../static/images/my11.png" />
                    <p>{{ $t('my-middle-zhcz') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/rechargeRecord'">
                    <img src="../../static/images/my12.png" />
                    <p>{{ $t('my-middle-czjl') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/settings'">
                    <img src="../../static/images/my13.png" />
                    <p>{{ $t('my-middle-grsz') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/mine/message'">
                    <img src="../../static/images/my05.png" />
                    <p>{{ $t('my-middle-wdxx') }}</p>

                </a>
            </li>
            <li>
                <a v-bind:href="'/applyWithdraw'">
                    <img src="../../static/images/my7.png" />
                    <p>{{ $t('my-middle-sqtx') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/withdrawRecord'">
                    <img src="../../static/images/my9.png" />
                    <p>{{ $t('my-middle-txjl') }}</p>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)">
                    <img src="../../static/images/my8.png" />
                    <p>{{ $t('my-middle-app') }}</p>
                </a>
            </li>
            <li>
                <a v-bind:href="'/customerService'">
                    <img src="../../static/images/my10.png" />
                    <p>{{ $t('my-middle-zskf') }}</p>
                </a>
            </li>
        </ul>
    </div>

    <div class="kbox"></div>
    <div class="kbox"></div>
    <div class="clear"></div>
    <div class="mypart4">
        <img src="../../static/images/my15.png" />
    </div>
    <div class="likebox my">
        <ul>

            <li v-for="(item, index) in recommendList">
                <a v-bind:href="`/taxRefund/detail/${item.id}`">
                    <img :src="item.productImg" class="proimg" />
                    <p class="tit">{{ item.productName }}</p>
                    <p class="price">￥{{ item.groupPrice }}<span>￥{{ item.marketPrice }}</span></p>
                    <p class="price"
                        style="background-color: #F79764; text-align: center; vertical-align: middle; line-height: 11px; color: red">
                        {{ $t('tax-refund-taxRefund') }}:￥{{ item.taxReturned }}</p>
                </a>
            </li>


        </ul>
        <br><br><br>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getUserOverview, getRecommendList } from '@/api/authed/mine.js'
const userOverview = ref(null)
const recommendList = ref([])
const levelImgSrc = ref('')
onMounted(() => {
    getUserOverview().then(res => {
        if (res && res.data) {
            userOverview.value = res.data
            levelImgSrc.value = '/images/vip' + userOverview.value.vipLevel + '.jpg'
        }
    })

    getRecommendList().then(res => {
        if (res && res.rows) {
            recommendList.value = res.rows
        }
    })
})

</script>



<style scoped></style>
