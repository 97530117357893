<template>
<div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="../../static/images/goback.png" /></a>
    </div>
    <div class="headerC">
      <p>{{ $t('bound-alipay-title') }}</p>
    </div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="kbox"></div>
<div class="addressDiv">
      <div class="addiv1">
    <div class="addiv1_l">{{$t('bound-id')}}：</div>
    <div class="addiv1_r">{{userInfo.id}}</div>
  </div>
  <div class="addiv1">
    <div class="addiv1_l">{{$t('bound-account')}}：</div>
    <div class="addiv1_r">{{userInfo.account}}</div>
  </div>
  <div class="addiv1">
    <div class="addiv1_l">{{$t('bound-name')}}：</div>
    <div class="addiv1_r red">{{ userInfo.realName }}</div>
  </div>
  <div class="addiv1">
    <div class="addiv1_l">{{$t('bound-alipay-account')}}：</div>
    <div class="addiv1_r"><input id="zfb" type="text" v-model="userInfo.alipayAccount"  /></div>
  </div>
  <div class="addiv1" style="text-align:center">

 <a  class="aui-btn aui-btn-danger" @click="submitChange">{{$t('bound-modify')}}</a></div>

</div>

</template>

<script setup>
import { ref, onMounted } from 'vue'
import { modifyUserInfo } from '@/api/authed/settings'
import { showToast } from 'vant';
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const userInfo = ref({})
onMounted(() => {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))

})

const submitChange = () => {
    if (userInfo.value.alipayAccount == '' || userInfo.value.alipayAccount == null || userInfo.value.alipayAccount == undefined) {
        showToast(t('bound-alipay-msg-alipay-empty'))
        return
    }

    const param = { alipayAccount: userInfo.value.alipayAccount, id: userInfo.value.id }
    modifyUserInfo(param).then(res => {
        if (res.code == 200) {
            showToast(t('userinfo-msg-success'))
            localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
        } else {
            showToast(res.msg)
        }
    })
}
</script>