<template>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">

        <van-cell-group inset>

            <van-cell>
                <template #title>
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <van-icon name="cross" style="position: absolute; top: 5px; left: 30px;" @click="toIndex()" />
                        <img src="/images/logo.png" style="max-width: 100%; max-height: 100%;" />
                    </div>
                </template>
            </van-cell>


            <van-cell>
                <van-field v-model="username" :placeholder="$t('login-placeholder-username')"  :class="{ 'error': isUsernameError }" />
                <template v-if="isUsernameError">
                    <div class="error-message">账号长度不能少于6位</div>
                </template>
            </van-cell>

            <van-cell>
                <van-field v-model="password" :type="passwordFieldType" :placeholder="$t('login-placeholder-password')"
                    :class="{ 'error': isPasswordError }">
                    <template #right-icon>
                        <van-icon :name="passwordIcon" class="eye-icon" size="32" @click="togglePasswordVisibility" />
                    </template>
                </van-field>
                <template v-if="isPasswordError">
                    <div class="error-message">$t('login-password-empty')</div>
                </template>
            </van-cell>

            <van-cell>
                <van-field v-model="captcha" :placeholder="$t('login-placeholder-captcha')" :class="{ 'error': isCaptchaError }">
                    <template #button>
                        <template v-if="codeUrl">
                            <img :src="codeUrl" @click="getCode()" />
                        </template>
                        <template v-else>
                            <van-button type="primary" size="small" @click="getCode()">{{$t('Obtain verification code')}}</van-button>
                        </template>
                        
                    </template>
                </van-field>
                <template v-if="isCaptchaError">
                    <div class="error-message">{{$t('login-captcha-empty')}}</div>
                </template>
            </van-cell>


            <van-cell>
                <van-button round block color="#ee0a24" textColor="#fff" :disabled="!isEnableSumbit" @click="userLogin()">
                    {{$t('login-loginbtn')}}
                </van-button>
            </van-cell>

            <van-cell clickable>
                <a style="color: #1989fa; font-size: 14px;" v-bind:href="'/register'">{{ $t('login-signup') }}</a>
            </van-cell>
        </van-cell-group>

</template>
  
<script setup>
import { ref, onMounted, computed } from 'vue'
import { getCodeImg, login } from '@/api/login.js'
import { setToken } from '@/utils/auth'
import { showToast } from 'vant';
const username = ref('')
const password = ref('')
const captcha = ref('')
const codeUrl = ref('')
const uuid = ref('')

const passwordFieldType = ref('password');
const passwordIcon = ref('eye-o');

const togglePasswordVisibility = () => {
    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    passwordIcon.value = passwordIcon.value === 'eye-o' ? 'eye' : 'eye-o';
};


onMounted(() => {
    getCode()
})

const getCode = () => {
    getCodeImg().then(res => {
        codeUrl.value = res.img
        uuid.value = res.uuid
    })
}

const toIndex = () => {
    window.location.href = '/'
}


const isUsernameError = computed(() => username.value.length < 6 && username.value !== '');
const isPasswordError = computed(() => password.value.length === 0 && password.value !== '');
const isCaptchaError = computed(() => captcha.value.length === 0 && captcha.value !== '');

const isEnableSumbit = computed(() => {
    return username.value.length >= 6 && password.value != '' && captcha.value != ''
})

const userLogin = () => {
    if (isEnableSumbit.value) {
        login(username.value, password.value, captcha.value, uuid.value).then(res => {
            if(res.code === 200){
                setToken(res.data.token)
                const expiryTime = new Date().getTime() + 12 * 60 * 60 * 1000;
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('expiryTime', expiryTime)
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                window.location.href = '/'
            }else{
                getCode()
            }
        }).catch(err => {
            getCode()
        })
    }
}


</script>
  
<style scoped>
@import 'vant/lib/index.css';

.van-cell-group {
    width: 100%;
    margin: 50px auto;
}

.error {
    color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
    text-align: left;
    margin-left: 10px;
}
</style>