<template>
    <div class="hdbox">
        <div class="hdbox0">
            <div class="hdbox_1">
                <a onclick="javascript:history.back(-1)" class="goback">
                    <img src="../static/images/goback.png"></a>
            </div>
            <div class="hdbox_2">
                <ul>
                    <li class="on"><a href="#m1">{{$t('vipshop-product')}}</a></li>
                    <li><a href="#m2">{{$t('vipshop-details')}}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="hbox"></div>

    <a name="m1">
        <div class="xqbox1">
            <div class="banner">
                <div id="fade_focus">
                    <div class="loading"></div>
                    <!-- <ul>

                        <li>
                            <img
                                src="https://a.vpimg2.com/upload/merchandise/pdcpos/1100000588/2021/0419/163/903b76c1-a465-475a-9a67-8105a31d8ca8_750x750_75.jpg" />
                        </li>

                        <li>
                            <img
                                src="https://a.vpimg4.com/upload/merchandise/pdcpos/1100000588/2019/0123/19/074cf7d1-0b0d-4fab-b29c-538122112abb_750x750_75.jpg" />
                        </li>

                        <li>
                            <img
                                src="https://a.vpimg3.com/upload/merchandise/pdcpos/1100000588/2020/0318/167/ca92da8e-c4bf-4272-938e-04fa4f0d6e62_750x750_75.jpg" />
                        </li>

                        <li>
                            <img
                                src="https://a.vpimg2.com/upload/merchandise/pdcpos/1100000588/2019/0123/69/d8713ef4-0cc3-4ce9-9168-32def3f95d99_750x750_75.jpg" />
                        </li>

                        <li>
                            <img
                                src="https://a.vpimg3.com/upload/merchandise/pdcpos/1100000588/2019/0123/178/93a95499-8234-40a8-92c8-5ebf513fd8d0_750x750_75.jpg" />
                        </li>

                        <li>
                            <img
                                src="https://a.vpimg3.com/upload/merchandise/pdcpos/1100000588/2019/0123/173/74c6f623-8a16-4f9a-8def-6620a5ce84d1_750x750_75.jpg" />
                        </li>

                        <li>
                            <img
                                src="https://a.vpimg2.com/upload/merchandise/pdcpos/1100000588/2021/0219/8/7f375e7b-b01a-4e11-a313-513d803c267c_750x750_75.jpg" />
                        </li>

                        <li>
                            <img
                                src="https://a.vpimg3.com/upload/merchandise/pdcpos/1100000588/2021/0219/177/c20b9e25-5c7e-4fa6-bc16-7216e595f22a_750x750_75.jpg" />
                        </li>

                    </ul> -->
                    <swiper class="swiper" :modules="modules" :pagination="{ clickable: true }" :centered-slides="true"
                        :autoplay="{
                            delay: 2000,
                            disableOnInteraction: false
                        }" :style="{
                            '--swiper-pagination-color': '#fff'
                        }">
                        <swiper-slide v-for="(item,index) in otherImages">
                            <img class="swiper-lazy swiper-lazy-loaded" :src="item">
                        </swiper-slide>

                    </swiper>

                </div>
            </div>
            <div class="aui-product-content">
                <div class="aui-real-price clearfix">
                    <span>
                        <i>￥</i>{{detail.vipPrice}}
                    </span>
                    <del><span class="aui-font-num">￥{{detail.marketPrice}}</span></del>
                    <div class="aui-settle-choice">
                        <span>{{$t('vipshop-text-cxj')}}</span>
                    </div>
                </div>
                <div class="aui-product-title">
                    <h2>
                        {{detail.productName}}
                    </h2>
                </div>

                <div class="aui-product-coupon">
                    <a class="aui-address-cell  aui-fl-arrow-clear">
                        <div class="aui-address-cell-bd">{{ $t('vipshop-delivery') }}</div>
                        <div class="aui-address-cell-ft">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$t('vipshop-delivery-to')}}</div>
                    </a>
                    <a class="aui-address-cell  aui-fl-arrow-clear">
                        <div class="aui-address-cell-bd">{{$t('tax-refund-detail-freight')}}</div>
                        <div class="aui-address-cell-ft">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('tax-refund-detail-freight-fee') }}</div>
                    </a>
                    <a class="aui-address-cell  aui-fl-arrow-clear">
                        <div class="aui-address-cell-bd">{{$t('tax-refund-detail-desc')}}</div>
                        <div class="aui-address-cell-ft">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('tax-refund-detail-desc-text') }}</div>
                    </a>
                    <a class="aui-address-cell  aui-fl-arrow-clear">
                        <div class="aui-address-cell-bd">{{$t('tax-refund-detail-sales')}}</div>
                        <div class="aui-address-cell-ft">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ detail.salesVolume }}</div>
                    </a>
                </div>
                <div class="dpdengji" style="margin-left: 15px;">
                    <ul>
                        <li>
                            <div class="v1">{{ $t('vipshop-points-msxf') }}<span>4.7</span></div>
                            <div class="v2">{{ $t('vipshop-text-high') }}</div>
                        </li>
                        <li>
                            <div class="v1">{{$t('vipshop-points-zlmy')}}<span>4.7</span></div>
                            <div class="v2">{{ $t('vipshop-text-high') }}</div>
                        </li>
                        <li>
                            <div class="v1">{{$t('vipshop-points-jghl')}}<span>4.7</span></div>
                            <div class="v2">{{ $t('vipshop-text-high') }}</div>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    </a>

    <a name="m2">
        <div class="kbox"></div>
    </a>

    <div class="xqtab">
        <div class="Menubox">
            <ul>
                <li class="hover" @click="setTab('two', 1, 3)" id="two1">{{$t('tax-refund-detail-graphtext')}}</li>
            </ul>
        </div>
        <div class="Contentbox">
            <div id="con_two_1">
                <div class="xqsub">
                    <ul>

                        <li v-for="(item,index) in detailImages">
                            <img
                                :src="item" />
                        </li>


                    </ul>
                </div>
            </div>

        </div>
    </div>
    <div class="clear"></div>
    <div class="xqbotbox">
        <!--div class="xqbotbox0">
            <div class="xqbotboxR">
                <a class="a2 sizetype" onclick="Furl()">立即购买转到唯品会,享受折扣</a>
            </div>
        </div-->
    </div>
    <!-- <script>
        function Furl() {
                    var spid = getUrlParam("spid");
                    if (spid != null) {

                        $.get("geturl.aspx?spid=" + spid, function (data, status) {
                            location.href = data;
                        });
                    }

                }
                //获取url中的参数  
                function getUrlParam(name) {
                    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象  
                    var r = window.location.search.substr(1).match(reg);  //匹配目标参数 
                    if (r != null) return unescape(r[2]); return null; //返回参数值    
                }
    </script> -->

    <div class="fbox"></div>
</template>

<script setup>
// import "../static/js/banner.js"
import "../static/css/style.css"
import "../static/css/aui.css"
import "../static/css/home.css"
import "../static/css/icon.css"
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useRoute } from 'vue-router'
import 'swiper/css'
import 'swiper/css/pagination'
import { onMounted, ref } from 'vue'
import { getProductDetail } from '../api/vipshopProduct.js'
const modules = ref([Pagination, Autoplay])
const route = useRoute()
const detailId = route.params.id
const detail = ref({})
const detailImages = ref([])
const otherImages = ref([])
const setTab = (name, cursel, n) => {
    for (let i = 1; i <= n; i++) {
        var menu = document.getElementById(name + i);
        var con = document.getElementById("con_" + name + "_" + i);
        menu.className = i == cursel ? "hover" : "";
        con.style.display = i == cursel ? "block" : "none";
    }
}

onMounted(() => {
    getProductDetail(detailId).then(res => {
       if (res && res.data) {
        detail.value = res.data
        detailImages.value = res.data.productDetail.split(',')
        otherImages.value = res.data.otherImgs.split(',')
        document.title = res.data.productName
       }
   })

})
</script>


<style>
</style>