<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback">
                    <img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>{{ $t('withdrawal-title') }}</p>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="kbox"></div>
    <div class="addressDiv">
        <div class="addiv1">
            <div class="addiv1_l">{{$t('settings-account')}}：</div>
            <div class="addiv1_r">{{ userInfo.account }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('withdrawal-balance')}}：</div>
            <div class="addiv1_r red">{{ (userInfo.balance / 100).toFixed(2) }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('withdrawal-apply-balance')}}：</div>
            <div class="addiv1_r">
                <input id="je" type="number" v-model="withdrawAmount" />
            </div>
        </div>
    


        <template v-if="withdrawType === '1'">
            <template v-if="userInfo.bank != null && userInfo.bank != undefined">
                <div class="addiv1">
                    <div class="addiv1_l">{{$t('bound-bankcard-bank')}}：</div>
                    <div class="addiv1_r"> {{ userInfo.bank }}</div>
                </div>
                <div class="addiv1">
                    <div class="addiv1_l">{{$t('bound-name')}}：</div>
                    <div class="addiv1_r"> {{ userInfo.realName }}</div>
                </div>
                <div class="addiv1">
                    <div class="addiv1_l">{{$t('bound-bankcard-cardno')}}：</div>
                    <div class="addiv1_r"> {{ userInfo.bankCardNo }}</div>
                </div>
            </template>
            <template v-else>
                <div class="addiv1">
                    <div class="addiv1_l">{{$t('bound-bankcard-bank')}}：</div>
                    <div class="addiv1_r"> {{$t('withdrawal-msg-bound-cardno')}}</div>
                </div>
                <div class="addiv1">
                    <div class="addiv1_l">{{$t('bound-name')}}：</div>
                    <div class="addiv1_r"></div>
                </div>
                <div class="addiv1">
                    <div class="addiv1_l">{{$t('bound-bankcard-cardno')}}：</div>
                    <div class="addiv1_r"></div>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="addiv1">
                    <div class="addiv1_l">{{$t('bound-alipay-account')}}：</div>
                    <div class="addiv1_r"> {{alipayStr}}</div>
                </div>

        </template>

        <div class="clear"></div>
        <div class="addiv1" style="border-top: 1px solid #eee;">
            <div class="addiv1_l">{{$t('withdrawal-type')}}：</div>
            <!-- <a class="aui-btn aui-btn-success">支付宝</a> &nbsp;
            <a class="aui-btn aui-btn-success">银行卡</a> -->
            <van-radio-group v-model="withdrawType" direction="horizontal">
                <van-radio name="1">{{$t('withdrawal-type-bankcard')}}</van-radio>
                <van-radio name="2" v-if="userInfo.alipayWithdrawSwitch === '1'">{{$t('bound-alipay-account')}}</van-radio>
            </van-radio-group>
        </div>
        <div class="addiv1" style="text-align: center">

            <a class="aui-btn aui-btn-danger" @click="applyWithdraw">{{$t('withdrawal-title')}}</a>
        </div>

    </div>
    <div class="clear"></div>
    <div class="kbox"></div>
    <div class="jfbox">



        <div class="jfbox1" v-for="(item, index) in myWithdrawList">
            <div class="jfbox1_R">
                <div class="jfbox1_R1">
                    <div class="v1"><van-button size="mini" disabled>{{ $t('withdrawal-apply-balance') }}</van-button></div>
                    <div class="v2"><van-button size="mini" disabled>{{ item.time }}</van-button></div>
                </div>
                <div class="jfbox1_R2">
                    <van-button size="mini" :type="getStatusButton(item.status)" disabled>{{ getStatusStr(item.status)
                    }}</van-button>
                    <div class="v4"><van-button size="mini" type="primary" disabled>{{ item.amount.toFixed(2)
                    }}</van-button></div>
                </div>
            </div>
            <div class="clear"></div>
        </div>

    </div>
    <br><br><br>
</template>
    
<script setup>
import { ref, onMounted } from 'vue'
import { modifyUserInfo } from '@/api/authed/settings'
import { showToast } from 'vant';
import { getMyWithdrawList, getUserInfo, withdraw } from '@/api/authed/mine'
import { computed } from 'vue';
import { useI18n } from "vue-i18n"
const { t } = useI18n();
const regex = /^[1-9]\d*$/;
const userInfo = ref({})
const withdrawType = ref("1")
const myWithdrawList = ref([])
const withdrawAmount = ref()
onMounted(() => {
    getUserInfo().then(res => {
        userInfo.value = res.data
    })
    getMyWithdrawList().then(res => {
        myWithdrawList.value = res.data
    })
})
const bankStr = computed(() => {
    return userInfo.value.bank != null && userInfo.value.bank != undefined ? '银行:' + userInfo.value.bank + ' 姓名:' + userInfo.value.realName + ' 卡号:' + userInfo.value.bankCardNo : '请先绑定银行卡'
})
const alipayStr = computed(() => {
    return userInfo.alipayAccount != null && userInfo.alipayAccount.length > 0 ? userInfo.alipayAccount : t('withdrawal-msg-bound-alipay')
})


const getStatusStr = (status) => {
    switch (status) {
        case 20:
            return t('withdrawal-status-created')
        case 21:
            return t('withdrawal-status-approved')
        case 22:
            return t('withdrawal-status-reject')
        default:
            return t('withdrawal-status-unknown')
    }
}

const getStatusButton = (status) => {
    switch (status) {
        case 20:
            return 'warning'
        case 21:
            return 'success'
        case 22:
            return 'danger'
        default:
            return 'default'
    }
}

const applyWithdraw = () => {
    if (!regex.test(withdrawAmount.value)) {
        showToast(t('withdrawal-msg-balance-empty'))
        return
    }
    if (withdrawAmount.value > userInfo.value.balance / 100) {
        showToast(t('withdrawal-msg-balance-not-enough'))
        return
    }
    if (withdrawAmount.value < userInfo.value.minWithdrawAmount) {
        showToast(t('withdrawal-msg-balance-le') + userInfo.value.minWithdrawAmount)
        return
    }

    const param = { accountType: withdrawType.value, amount: withdrawAmount.value }

    withdraw(param).then(res => {
        showToast(t('withdrawal-msg-success'))
        getMyWithdrawList().then(res => {
            myWithdrawList.value = res.data
        })
        getUserInfo().then(res => {
            userInfo.value = res.data
        })
    }).catch(err => {
        showToast(err.message)
    })

}
</script>