import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { showDialog,showFailToast } from 'vant'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'http://18.166.21.46:8080/api',
  baseURL: window.global_url.base_url,
  // 超时
  timeout: 30000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  const lang = localStorage.getItem('lang')
  if(lang !=null){
    config.headers['Accept-Language'] = lang.replace('_','-')
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})
 
// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const message = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      showDialog({
          title: '系统提示',
          message: '登录状态已过期，您可以继续留在该页面，或者重新登录',
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      })
    } else if (code === 500) {
      showFailToast(message)
      return Promise.reject(new Error(message))
    } else if (code !== 200) {
      showFailToast(message)
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    const response = error.response;
    if (response && response.status === 401) {
      // If the response status is 401, it means the user is unauthorized.
      showDialog({
        title: '系统提示',
        message: '登录状态已过期，请重新登录',
      }).then(() => {
        // Clear the token and redirect to the login page.
        localStorage.removeItem('token')
        localStorage.removeItem('expiryTime')
        location.href = '/login';
      });
    } else {
      showFailToast(response.data.message || "系统异常");
      return Promise.reject(error)
    }
  }
)

export default service
