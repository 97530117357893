<template>
    <title>{{ title }}</title>
    <!-- <div class="topssbox1"></div> -->
    <!-- <div style="width: 100%;">
        <van-dropdown-menu >
            <van-dropdown-item v-model="language" :options="languageOption" @change="languageChange"/>
        </van-dropdown-menu>
    </div> -->
    <div>
        <swiper class="swiper" :modules="modules" :pagination="{ clickable: true }" :centered-slides="true" :autoplay="{
            delay: 5000,
            disableOnInteraction: false
        }" :style="{
    '--swiper-pagination-color': '#d12d7d'
}">
            <swiper-slide>
                <img src="../static/images/banner1.jpg" class="swiper-lazy swiper-lazy-loaded">
            </swiper-slide>
            <swiper-slide>
                <img src="../static/images/banner2.jpg" class="swiper-lazy swiper-lazy-loaded">
            </swiper-slide>
            <swiper-slide>
                <img src="../static/images/banner3.jpg" class="swiper-lazy">
            </swiper-slide>
            <swiper-slide>
                <img src="../static/images/banner4.jpg" class="swiper-lazy">
            </swiper-slide>
            <swiper-slide>
                <img src="../static/images/banner5.jpg" class="swiper-lazy">
            </swiper-slide>
            <swiper-slide>
                <img src="../static/images/banner6.jpg" class="swiper-lazy">
            </swiper-slide>
        </swiper>
        <!-- Additional required wrapper -->


    </div>


    <div class="clear"></div>
    <div class="typeNav">
        <ul>

            <li v-for="(item, index) in groupList">
                <a v-bind:href="'/shopList?type=' + item.groupId">
                    <img :src="'images/' + item.groupId + '.png'" :alt="item.categoryName">
                    <p>{{ item.categoryName }}</p>
                </a>
            </li>


        </ul>
    </div>
    <div class="clear"></div>
    <div class="hotTit">
        <div class="hotTitL">
            <img src="../static/images/hotit.png">
        </div>
        <div class="hotTitR">
            <div class="scroll-container">
                <div ref="content" class="scroll-content">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ noticeContent }}
                </div>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div>
        <router-link to="/seckillList">
            <img src="../static/images/miaosha.png" />
        </router-link>
    </div>
    <!-- <div class="timeBuy">
            <div class="Buybox">
                <p><span>限时</span>抢购</p>
                <a href="shoplist.aspx.html" class="btn">全场一折起</a>
            </div>
            <ul>

                <li v-for="(item, index) in flashSaleList">
                    <a :href="`/shopList/${item.id}`">
                        <img :src="item.productImg" class="proimg">
                        <p>{{ item.productName }}</p>
                    </a>
                </li>


            </ul>
            <div class="clear"></div>
        </div> -->
    <div class="clear"></div>

    <div class="hotMarket">

        <van-row gutter="10" style="text-align: center; margin-top: 10px;">
            <van-col span="8">
                <router-link to="/shopList?type=otb2yhxb">
                    <img src="../static/images/a1.png">
                    <div>{{ $t('index-rm-dpnz') }}</div>
                </router-link>

            </van-col>
            <van-col span="8">
                <router-link to="/shopList?type=dpot8m5u">
                    <img src="../static/images/a2.png">
                    <div>{{ $t('index-rm-kjsm') }}</div>
                </router-link>
            </van-col>
            <van-col span="8">
                <router-link to="/shopList?type=axu1ike2">
                    <img src="../static/images/a3.png">
                    <div>{{ $t('index-rm-jjjf') }}</div>
                </router-link>
            </van-col>
        </van-row>
        <van-row gutter="10" style="text-align: center;">
            <van-col span="8">
                <router-link to="/shopList?type=x6kihigh">
                    <img src="../static/images/a4.png">
                    <div>{{ $t('index-rm-mzhf') }}</div>
                </router-link>

            </van-col>
            <van-col span="8">
                <router-link to="/shopList?type=2o396a6v">
                    <img src="../static/images/a5.png">
                    <div>{{ $t('index-rm-znjd') }}</div>
                </router-link>
            </van-col>
            <van-col span="8">
                <router-link to="/shopList?type=2jo673jj">
                    <img src="../static/images/a6.png">
                    <div>{{ $t('index-rm-jmxb') }}</div>
                </router-link>
            </van-col>
        </van-row>
    </div>
    <div class="clear"></div>
    <div class="kbox"></div>
    <div class="likebox" style="margin-top: 10px;">
        <!-- <div class="likeTit">
            <img src="../static/images/heart.png"><span>{{ $t('index-cnxh') }}</span>
        </div> -->
        <ul>

            <li v-for="(item, index) in guessLikeList">
                <a :href="`/shopList/${item.id}`">
                    <img :src="item.productImg" class="proimg">
                    <p class="tit">{{ item.productName }}</p>
                    <p class="price">￥{{ item.vipPrice }}<span>￥{{ item.marketPrice }}</span><img
                            src="../static/images/f3.png"></p>
                </a>
            </li>

        </ul>
    </div>

    <div class="fbox"></div>
</template>
  
<script setup>
// import "../static/css/style.css"
// import "../static/css/aui.css"
// import "../static/css/home.css"
// import "../static/css/icon.css"
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'

// import "/static/js/jquery-1.8.1.min.js"
// import "/static/admin/lib/layer/3.1.1/layer.min.js"
// import "/static/admin/lib/layer/3.1.1/theme/default/layer.css"
// import "/static/admin/lib/layer/3.1.1/msg.js"

// import { ref, onMounted, onUnmounted } from "vue"
import { ref } from 'vue'
import { onMounted } from "vue"
import { getGroupList, getFlashSaleList, getGuessLikeList } from "@/api/vipshopProduct"
import { getTitle } from '@/api/common'
// import { useI18n } from 'vue-i18n';
// const { locale } = useI18n()
const modules = ref([Pagination, Autoplay])
const groupList = ref([])
const flashSaleList = ref([])
const guessLikeList = ref([])
const title = ref('')
const content = ref(null);
const noticeContent = ref('');
// const language = ref(locale.value);
// const languageOption = [
//     {
//         text: '简体中文', value: 'zh_CN'
//     },
//     {
//         text: 'English', value: 'en_US'
//     },
//     {
//         text: 'Bahasa Malaysia', value: 'en_MY'
//     }

// ];
// if(localStorage.getItem('lang') == null){
//     localStorage.setItem('lang',locale.value)
// }else{
//     language.value = localStorage.getItem('lang')
// }
// const languageChange = (lang) =>{
//     locale.value = lang
//     localStorage.setItem('lang',locale.value)
// }
onMounted(() => {
    // title.value = localStorage.getItem('title')
    // if (!title.value || new Date().getTime() > localStorage.getItem('titleTime')) {
    // getTitle().then(res => {
    //     if (res && res.data) {
    //         title.value = res.data
    //         document.title = title.value
    //         localStorage.setItem('title', title.value)
    //         localStorage.setItem('titleTime', new Date().getTime() + 1000 * 60 * 6)
    //     }
    // })
    // } else {
    //     document.title = title.value
    // }

    getTitle().then(
        res => {
            if (res && res.data) {
                noticeContent.value = res.data
                const container = content.value;
                const containerWidth = container.offsetWidth;
                const contentWidth = container.scrollWidth;
                if (contentWidth >= containerWidth) {
                    let scrollPos = 0;

                    setInterval(() => {
                        scrollPos = scrollPos + 5;
                        if (scrollPos >= contentWidth) {
                            scrollPos = 0;
                        }
                        container.style.marginLeft = `-${scrollPos}px`;
                    }, 100); // 调整滚动速度，根据需要适当调整
                }
            }
        }
    )

    getGroupList().then(res => {
        if (res && res.rows) {
            groupList.value = res.rows
        }
    })

    getFlashSaleList().then(res => {
        if (res && res.data) {
            flashSaleList.value = res.data
        }
    })

    getGuessLikeList().then(res => {
        if (res && res.data) {
            guessLikeList.value = res.data
        }
    })

})


</script>
  
<style scoped>
.scroll-container {
    width: 30em;
    /* 设置容器的宽度，根据实际情况调整 */
    overflow: hidden;
    /* 隐藏容器内溢出部分 */
}

.scroll-content {
    white-space: nowrap;
    /* 让文字不换行 */
}

</style>
  
  