<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback">
                    <img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>{{ $t('my-group-order') }}</p>
            </div>
            <div class="headerR"></div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="myddstatu">
        <ul>
            <li :class="{ on: isAll }">
                <a v-bind:href="'/tabList/all'">{{ $t('my-group-all') }}</a>
            </li>
            <li :class="{ on: isWait }">
                <a v-bind:href="'/tabList/0'">{{ $t('my-group-wait') }}</a>
            </li>
            <li :class="{ on: isSucceed }">
                <a v-bind:href="'/tabList/1'">{{ $t('my-group-success') }}</a>
            </li>
            <li :class="{ on: isWaitTaxReturn }">
                <a v-bind:href="'/tabList/2'">{{ $t('my-group-waittax') }}</a>
            </li>
            <li :class="{ on: isAlreadyTaxRetuned }">
                <a v-bind:href="'/tabList/3'">{{ $t('my-group-refunded') }}</a>
            </li>
        </ul>

    </div>
    <div class="clear"></div>
    <div class="myddcon">

        <div class="myddcon1" v-for="(item, index) in groupPurchaseList">
            <div class="dpbox">
                <div class="dpL">
                    <a href="javascript:void(0)">
                        <span>{{$t('my-group-status')}}</span>
                        <img src="../../static/images/mre1.png">
                    </a>
                </div>
                <div class="dpR">{{ statusFormatter(item.orderStatus) }} </div>
            </div>
            <div class="shopbox">
                <div class="shopboxL">
                    <img :src="item.productImg" />
                </div>
                <div class="shopboxR">
                    <div class="shopboxR_1">
                        <div class="sbr1_1">{{ item.productName }}</div>
                        <div class="sbr1_2">
                            <p class="p1">￥{{ item.marketPrice? (item.marketPrice).toFixed(2):'0.00' }}</p>
                            <p class="p2">￥{{ item.groupPrice? (item.groupPrice).toFixed(2) :'0.00' }}</p>
                        </div>
                    </div>
                    <div class="shopboxR_2">
                        <p class="p3">{{$t('tax-refund-taxRefund')}}：{{ (item.taxReturned).toFixed(2) }}</p>
                        <p class="p4">×1</p>
                    </div>
                </div>
            </div>
            <div class="dphjbox">
                <p class="p5">{{$t('my-group-total')}}:<span>￥{{ item.orderStatus===3 ?(item.orderAmount+item.taxReturned).toFixed(2) : (item.orderAmount).toFixed(2) }}</span></p>
            </div>
            <div class="dpbtn" v-if="item.orderStatus == 1">
                <div class="dpbtn2">
                    <a @click="applyTaxReturnRow(item.id)">{{ $t('my-group-apply-tax-retund') }}</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { getGroupPurchaseList, applyTaxReturn } from '@/api/authed/mine.js'
import { showToast } from 'vant'
import { useRoute } from 'vue-router'
import { useI18n } from "vue-i18n"
const { t } = useI18n();
const groupPurchaseList = ref([])
const route = useRoute()
const status = ref('')
status.value = route.params.status
console.log(status)
if (status.value == 'all') {
    status.value = ''
}

const isAll = computed(() => route.path === '/tabList/all')
const isWait = computed(() => route.path === '/tabList/0')
const isSucceed = computed(() => route.path === '/tabList/1')
const isWaitTaxReturn = computed(() => route.path === '/tabList/2')
const isAlreadyTaxRetuned = computed(() => route.path === '/tabList/3')


onMounted(() => {
    getGroupPurchaseList(status.value).then(res => {
        if (res && res.data) {
            groupPurchaseList.value = res.data
        }
    })
})

const applyTaxReturnRow = (id) => {
    applyTaxReturn(id).then(res => {
        if (res && res.code == 200) {
            showToast({
                message: '申请返税成功',
                duration: 1000
            });
            getGroupPurchaseList(status.value).then(res => {
                if (res && res.data) {
                    groupPurchaseList.value = res.data
                }
            })
        }
    })
}

const statusFormatter = (value) => {
    if (value == '0') {
        return t('my-group-wait')
    }
    if (value == '1') {
        return t('my-group-success')
    }

    if (value == '2') {
        return t('my-group-waittax')
    }

    if (value == '3') {
        return t('my-group-refunded')
    }
}

</script>

<style></style>