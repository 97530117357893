<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback"><img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>{{$t('userinfo-title')}}</p>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="kbox"></div>
    <div class="addressDiv">
        <div class="addiv1">
            <div class="addiv1_l">{{$t('userinfo-id')}}：</div>
            <div class="addiv1_r">{{userInfo.id}}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('userinfo-account')}}：</div>
            <div class="addiv1_r">{{ userInfo.account }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('userinfo-nickname')}}：</div>
            <div class="addiv1_r red"><input id="nic" type="text" v-model="nickname" />
            </div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('userinfo-realname')}}：</div>
            <div class="addiv1_r"><input id="xm" type="text" v-model="realName" /></div>
        </div>
        <div class="addiv1" style="text-align:center">

            <a class="aui-btn aui-btn-danger" @click="submitChange">{{$t('userinfo-modify-data')}}</a>
        </div>

    </div>
    <div class="clear"></div>
    <div class="kbox"></div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { showToast } from 'vant';
import { modifyUserInfo } from '@/api/authed/settings'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const nickname = ref('')
const realName = ref('')
const userInfo = ref({})
const rex = /^[\u4e00-\u9fa5]{1,}$/
const submitChange = () => {
    if (nickname.value == '' || nickname.value == null || nickname.value == undefined) {
        showToast(t('userinfo-msg-nickname-empty'))
        return
    }
    if (!rex.test(nickname.value)) {
        showToast(t('userinfo-msg-nickname-chinese'))
        return
    }
    if (realName.value == '' || realName.value == null || realName.value == undefined) {
        showToast(t('userinfo-msg-realName-empty'))
        return
    }
   
    if (!rex.test(realName.value)) {
        showToast(t('userinfo-msg-realName-chinese'))
        return
    }

    if (nickname.value === userInfo.value.nickname && realName.value === userInfo.value.realName) {
        showToast(t('userinfo-msg-notchange'))
        return
    }

    const param = { nickname: nickname.value, realName: realName.value, id: userInfo.value.id }
    modifyUserInfo(param).then(res => {
        if (res.code == 200) {
            showToast(t('userinfo-msg-success'))
            userInfo.value.nickname = nickname.value
            userInfo.value.realName = realName.value
            localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
        } else {
            showToast(res.msg)
        }
    })
}

onMounted(() => {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
    nickname.value = userInfo.value.nickname
    realName.value = userInfo.value.realName
})
</script>