import { createApp } from 'vue'
import App from './App.vue'
import $ from 'jquery'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Toast, Dialog, Cell, CellGroup, Field, Button, Icon,RadioGroup, Radio,Badge,List,Image,Col,Row,DropdownMenu, DropdownItem,Picker } from 'vant';
import { createI18n } from 'vue-i18n';

import en_US from '../lang/en-US.json';
import zh_CN from '../lang/zh-CN.json';
import en_MY from '../lang/en-MY.json';
window.$ = $

const i18n = createI18n({
  legacy: false, // 如果你不需要对旧版本的 vue-i18n 进行迁移，可以设置为 false
  locale: localStorage.getItem('lang') || 'zh_CN', // 默认语言
  messages: {
    en_US,
    zh_CN,
    en_MY
  },
});

const router = createRouter({
  mode: 'hash',
  history: createWebHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//     if(to.meta && to.meta.title){
//       document.title = to.meta.title
//     }
//     next();
// });

const app = createApp(App)
// app.config.productionTip = false;
// app.config.globalProperties.$copterm = {
//   title: 'My Awesome Vue App',
// };
// // 设置网站图标
// const favicon = '/favicon.ico';
// const link = document.createElement('link');
// link.rel = 'icon';
// link.href = favicon;
// document.head.appendChild(link);
app.use(VueAwesomeSwiper)
app.use(router)
app.use(Toast)
  .use(Dialog)
  .use(Cell)
  .use(CellGroup)
  .use(Field)
  .use(Button)
  .use(Icon)
  .use(RadioGroup)
  .use(Radio)
  .use(Badge)
  .use(List)
  .use(Image)
  .use(Col)
  .use(Row)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Picker)
  .use(i18n)

app.mount('#app')