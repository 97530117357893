<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback">
                    <img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>{{ $t('recharge-record-title') }}</p>
            </div>
            <div class="headerR"></div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>

    <div class="clear"></div>
    <div class="kbox"></div>
    <div class="jfbox">

        
        <div class="jfbox1" v-for="(item, index) in myRechargeList">
            <div class="jfbox1_R">
                <div class="jfbox1_R1">
                    <div class="v1"><van-button size="mini" disabled>{{ $t('recharge-title') }}</van-button></div>
                    <div class="v2"><van-button size="mini" disabled>{{ item.time }}</van-button></div>
                </div>
                <div class="jfbox1_R2">
                    <van-button size="mini" :type="item.status==11?'success':'danger'" disabled>{{ getStatusStr(item.status) }}</van-button>
                    <div class="v4"><van-button size="mini" type="primary" disabled>{{ item.amount.toFixed(2) }}</van-button></div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
    <br><br><br>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getMyRechargeList } from '@/api/authed/mine'
import { useI18n } from "vue-i18n"
const { t } = useI18n();
const myRechargeList = ref([])
onMounted(() => {
    getMyRechargeList().then(res => {
        myRechargeList.value = res.data
    })
})

const getStatusStr = (status) => {
    switch (status) {
        case 10:
            return t('recharge-status-wait')
        case 11:
            return t('recharge-status-success')
        case 1:
            return t('recharge-status-success') 
        default:
            return t('recharge-status-unknown')
    }
}
</script>

<style scoped></style>