<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback">
                    <img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>{{$t('settings-title')}}</p>
            </div>
            <div class="headerR"></div>
        </div>
    </div>

    <div class="hbox"></div>
    <div class="jsyhq pad0">
        <!-- <div class="mytb">
            <a v-bind:href="'/settings/userInfo'">
                <div class="mytbL">
                    <img src="../../static/images/tx.png" />
                </div>
                <div class="mytbC">
                    <p class="p1">{{ userInfo.nickname }}</p>
                    <p class="p2">账号:{{ userInfo.account }}</p>
                </div>
                <div class="mytbR">
                    <img src="../../static/images/more.png">
                </div>
                <div class="clear"></div>
            </a>
        </div> -->
        <van-cell is-link to="/settings/userInfo" center>
            <template #title>
                <div class="mytb">
                    <div class="mytbL">
                        <img src="/images/tx.png" />
                    </div>
                    <div class="mytbC">
                        <p class="p1">{{ userInfo.nickname }}</p>
                        <p class="p2">{{$t('settings-account')}}:{{ userInfo.account }}</p>
                    </div>
  
                </div>
            </template>
        </van-cell>

        <van-cell :title="$t('settings-alipay')" is-link :to="'/settings/alipayInfo'">
            <template #value>
                <van-button size="mini" :type="getBindButton(userInfo.alipayAccount)" disabled>{{
                    userInfo.alipayAccount ? $t('settings-bound') : $t('settings-unbound') }}</van-button>
            </template>
        </van-cell>
        <van-cell :title="$t('settings-wechat')" is-link :to="'/settings/wechatInfo'">
            <template #value>
                <van-button size="mini" :type="getBindButton(userInfo.wechatAccount)" disabled>{{
                    userInfo.wechatAccount ? $t('settings-bound') : $t('settings-unbound') }}</van-button>
            </template>
        </van-cell>
        <van-cell :title="$t('settings-bank')" is-link :to="'/settings/bankInfo'">
            <template #value>
                <van-button size="mini" :type="getBindButton(userInfo.bankCardNo)" disabled>{{
                    userInfo.bankCardNo ? $t('settings-bound') : $t('settings-unbound') }}</van-button>
            </template>
        </van-cell>
        <van-cell :title="$t('settings-address')" is-link :to="'/settings/addressInfo'">
            <template #value>
                <van-button size="mini" :type="getBindButton(userInfo.shippingAddress)" disabled>{{
                    userInfo.shippingAddress ? $t('settings-bound') : $t('settings-unbound') }}</van-button>
            </template>
        </van-cell>
        <van-cell :title="$t('settings-modify-password')" is-link :to="'/settings/modifyPassword'">
        </van-cell>
    </div>






    <div class="clear"></div>
    <div class="tuichu">
        <!-- <a @click="logout()">退出当前帐户</a> -->
        <van-button type="danger" size="large" @click="logout()">{{$t('settings-logout')}}</van-button>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import { removeToken } from '@/utils/auth'
const userInfo = ref({})
const router = useRouter()
const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('expiryTime')
    localStorage.removeItem('userInfo')
    removeToken()
    window.location.href='/'
}
onMounted(() => {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
})

const getBindButton = (value) => {
    if (value) {
        return 'success'
    } else {
        return 'danger'
    }
}
</script>
<style scoped></style>