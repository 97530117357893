<template>
    <div class="headerbox">
        <div class="header">
            <div class="headerL">
                <a onclick="javascript:history.back(-1)" class="goback"><img src="../../static/images/goback.png" /></a>
            </div>
            <div class="headerC">
                <p>{{ $t('change-password-title')}} </p>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="kbox"></div>
    <div class="addressDiv">
        <div class="addiv1">
            <div class="addiv1_l">{{$t('bound-id')}}：</div>
            <div class="addiv1_r">{{ userInfo.id }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('bound-account')}}：</div>
            <div class="addiv1_r">{{ userInfo.account }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('bound-name')}}：</div>
            <div class="addiv1_r red">{{ userInfo.realName }}</div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('change-password-oldpassword')}}：</div>
            <div class="addiv1_r"><input id="p" type="password" v-model="oldPassword" /></div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('change-password-newpassword')}}：</div>
            <div class="addiv1_r"><input id="p1" type="password" v-model="newPassword" /></div>
        </div>
        <div class="addiv1">
            <div class="addiv1_l">{{$t('change-password-repeatpassword')}}：</div>
            <div class="addiv1_r"><input id="p2" type="password" v-model="repeatPassword" /></div>
        </div>
        <div class="addiv1" style="text-align:center">

            <a class="aui-btn aui-btn-danger" @click="submitChange">{{$t('userinfo-modify-data')}}</a>
        </div>

    </div>
</template>
    
<script setup>
import { ref, onMounted } from 'vue'
import { modifyUserInfo } from '@/api/authed/settings'
import { showToast } from 'vant';
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const userInfo = ref({})
const oldPassword = ref('')
const newPassword = ref('')
const repeatPassword = ref('')
const rex = /^.{6,32}$/
onMounted(() => {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))

})

const submitChange = () => {
    if (oldPassword.value == '' || oldPassword.value == null || oldPassword.value == undefined) {
        showToast(t('change-password-msg-oldpassword-empty'))
        return
    }

    if (newPassword.value == '' || newPassword.value == null || newPassword.value == undefined) {
        showToast(t('change-password-msg-newpassword-empty'))
        return
    }

    if (repeatPassword.value == '' || repeatPassword.value == null || repeatPassword.value == undefined) {
        showToast(t('change-password-msg-repeatpassword-empty'))
        return
    }

    if (!rex.test(newPassword.value)) {
        showToast(t('change-password-msg-newpassword-length'))
        return
    }

    if (newPassword.value != repeatPassword.value) {
        showToast(t('change-password-msg-repeatpassword-wrong'))
        return
    }

    const param = { oldPassword: oldPassword.value, password: newPassword.value, id: userInfo.value.id }
    modifyUserInfo(param).then(res => {
        if (res.code == 200) {
            showToast(t('userinfo-msg-success'))
            localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
        } else {
            showToast(res.msg)
        }
    }).catch(err => {
        showToast(t('change-password-msg-failed'))
    })
}
</script>